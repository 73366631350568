import { Helmet } from 'react-helmet'
import { useTenantConfig } from '../../hooks/TenantConfig'

/**
 * IMPORTANT: `AppHelmet` is only able to render `<head>` tags using React (JavaScript). Web crawlers generally do not run JavaScript, so if you need to set tags for SEO purposes then do that in `index.html`.
 */
const AppHelmet = () => {
  const tenantConfig = useTenantConfig()

  return (
    <Helmet>
      <title>{tenantConfig.title}</title>
      {tenantConfig.themeColor && <meta name="theme-color" content={tenantConfig.themeColor} />}
      <link href={tenantConfig.theme.font.url} rel="stylesheet" />
    </Helmet>
  )
}

export default AppHelmet

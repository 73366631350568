import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from '@emotion/react'
import { Freshchat } from 'reactjs-freshchat'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ErrorBoundary from './components/ErrorBoundary/error_boundary'
import { GlobalStyles } from '@leadrilla/pulsar'
import { routes, renderRoute } from './routes'
import AuthProvider, { UserContext } from './components/AuthProvider/auth_provider'
import NotificationBar from './components/NotificationBar/notification_bar'
import AppHelmet from './components/AppHelmet/app_helmet'
import {
  TenantConfigProvider,
  getNonUserSpecificTenantConfig,
  isLeadrilla,
} from './hooks/TenantConfig'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './styles/styles.scss'

const nonUserSpecificTenantConfig = getNonUserSpecificTenantConfig()
const queryClient = new QueryClient()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a4424c1991bd4ca190122f322cc4dc4c@o423442.ingest.sentry.io/5353932',
    environment: process.env.REACT_APP_VERCEL_URL,
  })
  Sentry.setTag('tenant', nonUserSpecificTenantConfig?.name)
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  stripeAccount: nonUserSpecificTenantConfig.connected_stripe_account_id,
})

try {
  sessionStorage.removeItem('pusher_enabled')
} catch (e) {
  console.log(e)
}

render(
  <ErrorBoundary level="app">
    <Router>
      <AuthProvider>
        <TenantConfigProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={nonUserSpecificTenantConfig.theme}>
              <AppHelmet />
              <GlobalStyles />
              {isLeadrilla() && <Freshchat token={'bb1226ca-45e1-45ee-9333-327ffcabdc86'} />}
              <Elements stripe={stripePromise}>
                <NotificationBar>
                  <UserContext.Consumer>
                    {({ user }) => (
                      <Switch>{routes.map((route) => renderRoute(route, user))}</Switch>
                    )}
                  </UserContext.Consumer>
                </NotificationBar>
              </Elements>
            </ThemeProvider>
          </QueryClientProvider>
        </TenantConfigProvider>
      </AuthProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)

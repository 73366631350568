import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Inline,
  PrimaryButton,
  TextButton,
  Icon,
  Text,
  WrapperButton,
  Drawer,
} from '@leadrilla/pulsar'
import styled from '@emotion/styled'

import { UserContext } from '../AuthProvider/auth_provider'
import WebsiteMobileMenu from '../../Website/WebsiteMobileMenu/website_mobile_menu'
import logo from '../../assets/svg/leadrilla-logo-horizontal-subtle.svg'
import useImage from '../../hooks/image'
import { useTenantConfig, isLeadrilla } from '../../hooks/TenantConfig'

function Navbar(props) {
  const { user } = useContext(UserContext)
  const tenantConfig = useTenantConfig()

  let dashboardLink = '/dash/campaigns'

  if (user && user.type === 'admin') {
    dashboardLink = '/dash/metrics'
  }

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const openMobileMenu = () => {
    if (window.fcWidget) {
      window.fcWidget.hide()
    }
    setShowMobileMenu(true)
  }

  const closeMobileMenu = () => {
    if (window.fcWidget) {
      window.fcWidget.show()
    }
    setShowMobileMenu(false)
  }

  const { image: iconLogo } = useImage(
    isLeadrilla() ? 'leadrilla-logo-blue.svg' : `${tenantConfig.tenant}-icon.svg`
  )
  const { image: whiteLabelLogo } = useImage(`${tenantConfig.tenant}-logo.svg`)

  return (
    <div>
      {/* Desktop Navbar */}
      <div className="hidden lg:block">
        <StyledNavbar
          insetSquish="m"
          position="fixed"
          width="100vw"
          background="var(--colors-foreground)"
        >
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-3">
              <Link to="/">
                {isLeadrilla() ? (
                  <img src={logo} alt="brand logo" />
                ) : (
                  <StyledLogo src={whiteLabelLogo} alt="brand logo" />
                )}
              </Link>
            </div>
            <div className="col-span-6">
              <Box>
                <Inline alignX="center" space="s">
                  {isLeadrilla() && (
                    <>
                      <Box insetSquish="s">
                        <Link to="/">
                          <Text tone="neutral" size="big">
                            Home
                          </Text>
                        </Link>
                      </Box>
                      <Box insetSquish="s">
                        <Link to="/leads">
                          <Text tone="neutral" size="big">
                            Leads
                          </Text>
                        </Link>
                      </Box>
                      <Box insetSquish="s">
                        <Link to="/leads/call-campaigns">
                          <Text tone="neutral" size="big">
                            Calls
                          </Text>
                        </Link>
                      </Box>
                      <Box insetSquish="s">
                        <Link to="/contact">
                          <Text tone="neutral" size="big">
                            Contact
                          </Text>
                        </Link>
                      </Box>
                    </>
                  )}
                </Inline>
              </Box>
            </div>
            <div className="col-span-3">
              <Inline space="m" alignX="end">
                {user ? (
                  <Link to={dashboardLink}>
                    <PrimaryButton size="big">Dashboard</PrimaryButton>
                  </Link>
                ) : (
                  <>
                    <Link to="/login">
                      <TextButton size="big">Login</TextButton>
                    </Link>
                    {!tenantConfig.signup.invite_only && (
                      <Link to="/signup">
                        <PrimaryButton size="big">Sign Up</PrimaryButton>
                      </Link>
                    )}
                  </>
                )}
              </Inline>
            </div>
          </div>
        </StyledNavbar>
      </div>

      {/* Mobile Navbar (collapsed) */}
      <div className="lg:hidden">
        <StyledNavbar
          insetSquish="m"
          position="fixed"
          width="100vw"
          background="var(--colors-foreground)"
        >
          <div className="grid grid-cols-12 items-center">
            {isLeadrilla() ? (
              <>
                <div className="col-span-4">
                  <MenuIconWrapper onClick={openMobileMenu}>
                    <Icon name="menu-tt" size="l" />
                  </MenuIconWrapper>
                </div>
                <div className="col-span-4 mx-auto">
                  <Link to="/">
                    <StyledIconLogo src={iconLogo} />
                  </Link>
                </div>
                <div className="col-span-4 text-right">
                  {user ? (
                    <Link to={dashboardLink}>
                      <PrimaryButton size="big">Dash</PrimaryButton>
                    </Link>
                  ) : (
                    <Link to="/signup">
                      <PrimaryButton>Sign up</PrimaryButton>
                    </Link>
                  )}
                </div>
              </>
            ) : (
              <div className="col-span-4 col-start-6">
                <Link to="/">
                  <img style={{ height: '40px' }} src={whiteLabelLogo} alt="brand logo" />
                </Link>
              </div>
            )}
          </div>
        </StyledNavbar>
      </div>

      {/* Mobile Navbar (expanded) */}
      <Drawer
        inset="none"
        open={showMobileMenu}
        onClose={closeMobileMenu}
        defaultClose={false}
        width="full"
        position="left"
      >
        <WebsiteMobileMenu close={closeMobileMenu} {...props} />
      </Drawer>
    </div>
  )
}

const MenuIconWrapper = styled(WrapperButton)`
  & svg {
    height: 24px !important;
    width: auto !important;
  }
`

const StyledNavbar = styled(Box)`
  border-bottom: 1px solid var(--colors-hairline);
  z-index: 1;
`

const StyledIconLogo = styled('img')``
const StyledLogo = styled('img')`
  max-height: 32px;
  max-width: 120px;
`

export default Navbar

import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'

import Dashboard from './AgentPortal/Dashboard/dashboard'
import WebsiteMain from './Website/WebsiteMain/website_main'
import FullPageLoader from './components/FullPageLoader/full_page_loader'
import { isLeadrilla } from './hooks/TenantConfig'
import { isUserAdminForTeam, isAdminForAtLeastOneTeam } from './helpers/teams'

const loadable = (loader) => Loadable({ loader, loading: Loading })

const Loading = (props) => {
  if (props.pastDelay) {
    return <FullPageLoader />
  } else {
    return null
  }
}

export const routes = [
  {
    name: 'Dashboard',
    exact: false,
    path: '/dash',
    component: Dashboard,
    auth: true,
    children: [
      {
        name: 'Campaigns',
        exact: true,
        path: '/dash/campaigns',
        component: loadable(() => import('./AgentPortal/Campaigns/campaigns')),
      },
      {
        name: 'Call Log',
        exact: true,
        path: '/dash/call-log',
        component: loadable(() => import('./AgentPortal/CallLog/call_log')),
      },
      {
        name: 'Create Campaign',
        exact: true,
        path: '/dash/campaigns/create',
        component: loadable(() => import('./AgentPortal/CampaignConfig/campaign_config')),
      },
      {
        name: 'Edit Campaign',
        exact: true,
        path: '/dash/campaigns/:id/edit',
        component: loadable(() => import('./AgentPortal/CampaignConfig/campaign_config')),
      },
      {
        name: 'Campaign Details',
        exact: true,
        path: '/dash/campaigns/:id',
        component: loadable(() => import('./AgentPortal/CampaignDetails/campaign_details')),
      },
      {
        name: 'My Leads',
        exact: true,
        path: '/dash/my-leads',
        component: loadable(() => import('./AgentPortal/MyLeads/my_leads')),
      },
      {
        name: 'Lead Detail',
        exact: true,
        path: '/dash/leads/:id',
        component: loadable(() => import('./AgentPortal/LeadDetail/lead_detail')),
      },
      {
        name: 'Settings',
        exact: true,
        path: '/dash/settings',
        component: loadable(() => import('./AgentPortal/DashSettings/dash_settings')),
      },
      {
        name: 'Products',
        exact: true,
        path: '/dash/products',
        admin: true,
        component: loadable(() => import('./AgentPortal/Products/products')),
      },
      {
        name: 'Product Details',
        exact: true,
        path: '/dash/products/:id',
        admin: true,
        component: loadable(() => import('./AgentPortal/ProductDetails/product_details')),
      },
      {
        name: 'Users',
        exact: true,
        path: '/dash/users',
        admin: true,
        component: loadable(() => import('./AgentPortal/Users/users')),
      },
      {
        name: 'User Details',
        exact: true,
        path: '/dash/users/:id',
        admin: true,
        component: loadable(() => import('./AgentPortal/UserDetails/user_details')),
      },
      {
        name: 'Refer',
        exact: true,
        path: '/dash/refer',
        component: loadable(() => import('./AgentPortal/Refer/refer')),
      },
      {
        name: 'Platform Metrics',
        exact: true,
        admin: true,
        path: '/dash/metrics',
        component: loadable(() => import('./AgentPortal/PlatformMetrics/platform_metrics')),
      },
      {
        name: 'Billing',
        exact: true,
        path: '/dash/billing',
        component: loadable(() => import('./AgentPortal/Billing/billing')),
      },
      {
        name: 'Integrations',
        exact: true,
        path: '/dash/integrations',
        component: loadable(() => import('./AgentPortal/Integrations/integrations')),
      },
      {
        name: 'Marketplace',
        exact: true,
        path: '/dash/marketplace',
        component: loadable(() => import('./AgentPortal/Marketplace/marketplace')),
      },
      {
        name: 'Promotions',
        exact: true,
        path: '/dash/promotions',
        admin: true,
        component: loadable(() => import('./AgentPortal/Promotions/promotions')),
      },
      {
        name: 'Promotion Management',
        exact: true,
        path: '/dash/promotions/:id',
        admin: true,
        component: loadable(() => import('./AgentPortal/PromotionManagement/promotion_management')),
      },
      {
        name: 'Call Demand',
        exact: true,
        path: '/dash/call-demand',
        admin: true,
        component: loadable(() => import('./AgentPortal/CallDemand/call_demand')),
      },
      {
        name: 'Organizations',
        exact: true,
        path: '/dash/organizations',
        admin: true,
        component: loadable(() => import('./AgentPortal/Organizations/organizations')),
      },
      {
        name: 'Faqs Dashboard',
        exact: true,
        path: '/dash/faq',
        component: loadable(() => import('./AgentPortal/Faqs/Faqs')),
      },
      {
        name: '404',
        exact: true,
        path: '/404',
        component: loadable(() => import('./AgentPortal/404/404')),
      },
      {
        name: 'Teams',
        exact: true,
        path: '/dash/teams',
        // Must be admin of at least one team to see this page
        team_admin_for_one: true,
        component: loadable(() => import('./AgentPortal/Teams/teams')),
      },
      {
        name: 'Member Details',
        exact: true,
        path: '/dash/teams/:teamId/members/user/:userId',
        team_admin: true,
        component: loadable(() => import('./AgentPortal/Teams/MemberDetails/member_details')),
      },
      {
        name: 'Team Details',
        exact: true,
        path: '/dash/teams/:teamId/:section/:action?',
        team_admin: true,
        component: loadable(() => import('./AgentPortal/Teams/team_details')),
      },
      {
        name: '404 Redirect',
        exact: false,
        path: '*',
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    name: 'WebsiteMain',
    exact: false,
    path: '/',
    component: WebsiteMain,
    children: [
      {
        name: 'Home',
        exact: true,
        path: '/',
        component: loadable(() =>
          isLeadrilla()
            ? import('./Website/WebsiteHome/website_home')
            : import('./components/Login/login')
        ),
      },
      {
        name: 'Platform',
        exact: true,
        path: '/explore-the-platform',
        component: loadable(() =>
          isLeadrilla()
            ? import('./Website/WebsitePlatform/website_platform')
            : import('./AgentPortal/404/404')
        ),
      },
      {
        name: 'Campaign',
        exact: true,
        path: '/leads/lead-campaigns',
        component: loadable(() =>
          isLeadrilla()
            ? import('./Website/WebsiteLeadCampaigns/website_lead_campaigns')
            : import('./AgentPortal/404/404')
        ),
      },
      {
        name: 'Calls Campaign',
        exact: true,
        path: '/leads/call-campaigns',
        component: loadable(() =>
          isLeadrilla()
            ? import('./Website/WebsiteCallCampaigns/website_call_campaigns')
            : import('./AgentPortal/404/404')
        ),
      },
      {
        name: 'Contact',
        exact: true,
        path: '/contact',
        component: loadable(() =>
          isLeadrilla()
            ? import('./components/ContactPage/contact_page')
            : import('./AgentPortal/404/404')
        ),
      },
      {
        name: 'Login',
        exact: true,
        path: '/login',
        component: loadable(() => import('./components/Login/login')),
      },
      {
        name: 'Signup',
        exact: false,
        path: '/signup',
        component: loadable(() => import('./components/Signup/signup')),
      },
      {
        name: 'Refer',
        exact: false,
        path: '/refer/:referral_code',
        component: loadable(() => import('./components/Referral/referral')),
      },
      {
        name: 'Forgot Password',
        exact: true,
        path: '/forgot-password',
        component: loadable(() => import('./components/ForgotPassword/forgot_password')),
      },
      {
        name: 'Reset Forgot Password',
        exact: true,
        path: '/reset-password',
        component: loadable(() => import('./components/ResetPassword/reset_forgot_password')),
      },
      {
        name: 'Email Verification',
        exact: true,
        path: '/email-verification',
        component: loadable(() => import('./components/EmailVerification/email_verification')),
      },
      {
        name: 'Privacy Policy',
        exact: true,
        path: '/privacy-policy',
        component: loadable(() => import('./components/PrivacyPolicy/privacy_policy')),
      },
      {
        name: 'Terms and Conditions',
        exact: true,
        path: '/terms-and-conditions',
        component: loadable(() => import('./components/TermsAndConditions/terms_and_conditions')),
      },
      {
        name: 'Marketplace Public',
        exact: true,
        path: '/leads/marketplace',
        component: loadable(() => import('./Website/WebsiteMarketplace/website_marketplace')),
      },
      {
        name: 'Leads Public',
        exact: true,
        path: '/leads/',
        component: loadable(() => import('./Website/WebsiteLeads/website_leads')),
      },
      {
        name: 'Final Expense',
        exact: true,
        path: '/leads/final-expense',
        component: loadable(() => import('./Website/WebsiteFinalExpense/website_final_expense')),
      },
      {
        name: 'General Insurance',
        exact: true,
        path: '/leads/general-life/',
        component: loadable(() => import('./Website/WebsiteGeneralInsurance/general_insurance')),
      },
      {
        name: 'ACA Insurance',
        exact: true,
        path: '/leads/aca-health-insurance/',
        component: loadable(() => import('./Website/WebsiteACA/WebsiteACA')),
      },
      {
        name: '404',
        exact: true,
        path: '/404',
        component: loadable(() => import('./AgentPortal/404/404')),
      },
      {
        name: '404 Redirect',
        exact: false,
        path: '*',
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    name: '404',
    exact: true,
    path: '/404',
    component: loadable(() => import('./AgentPortal/404/404')),
  },
]

export const renderRoute = (
  {
    name,
    path,
    component,
    env,
    children = [],
    auth = false,
    exact = true,
    admin = false,
    team_admin = false,
    team_admin_for_one = false,
  },
  user = null
) => {
  if (env && env !== process.env.NODE_ENV) {
    return null
  }

  if (auth && !user) {
    component = () => <Redirect to={`/login?redirect_url=${window.location.pathname}`} />
  } else if (admin && (!user || user.type !== 'admin')) {
    component = () => <Redirect to="/dash/my-leads" />
  } else if (team_admin_for_one) {
    // If not admin for at least one team, redirect to campaigns page
    if (!user || (user.type !== 'admin' && !isAdminForAtLeastOneTeam(user.teams))) {
      component = () => <Redirect to="/dash/campaigns" />
    }
  } else if (team_admin) {
    const teamId = window.location.pathname?.split('/teams/')[1]?.split('/')[0]
    if (!isUserAdminForTeam(user, teamId)) {
      // TODO - if the user is not an admin for ANY team then redirect to "/"
      component = () => <Redirect to="/dash/teams" />
    }
  }

  if (children.length) {
    const Component = component
    return (
      <Route
        key={name}
        {...{ exact, path }}
        render={(props) => (
          <Component {...props}>
            <Switch>{children.map((child) => renderRoute(child, user))}</Switch>
          </Component>
        )}
      />
    )
  } else {
    return <Route key={name} {...{ exact, path, component }} />
  }
}

import {
  Box,
  Dialog,
  Divider,
  Heading,
  Icon,
  Inline,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextLink,
} from '@leadrilla/pulsar'
import React, { useContext } from 'react'
import { CallCampaignDispatchContext, actionType } from '../../AgentPortal/CallBar/CallContext'
import backend from '../../backend'
import { UserContext } from '../AuthProvider/auth_provider'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const FailedToCallUserModal = ({
  open,
  setOpen,
  leadId,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  leadId: number
}) => {
  const { user } = useContext(UserContext)
  const callDispatch = useContext(CallCampaignDispatchContext)

  const callLeadAgain = async () => {
    setOpen(false)

    try {
      const resp = await backend.post(`/leads/${leadId}/call`)

      if (resp.ok && resp?.body?.call && callDispatch) {
        const call = resp.body.call

        // Fetch the lead
        let lead = undefined

        if (call.user_lead?.lead_id) {
          const resp = await backend.get(`/leads/${call.user_lead.lead_id}`)
          if (resp?.body) lead = resp.body
        }

        callDispatch({
          type: actionType.UPDATE,
          payload: {
            enabled: false,
            ipp: true,
            call: { ...call, type: 'outbound', failed_user: undefined, failed_lead: undefined },
            lead,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const close = () => {
    setOpen(false)
    if (callDispatch) {
      callDispatch({
        type: actionType.REMOVE,
        payload: { enabled: false },
      })
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} width="content">
      <Box width="100%" maxWidth="415px" paddingTop="m" paddingBottom="l" paddingX="m">
        <Stack space="m">
          <Inline alignX="between" alignY="center">
            <Heading level={3}>Call Failed to Connect to You</Heading>

            <Box onClick={close} cursor="pointer">
              <Icon name="close" tone="light" size="m" />
            </Box>
          </Inline>
          <Divider />
          <Stack space="m">
            <Inline space={'xxs'}>
              <Text component="p" pLineHeight={1.5}>
                We called you at
              </Text>
              <Text weight="stronger" component="p" pLineHeight={1.5}>
                {parsePhoneNumberFromString(user.phone, 'US')?.formatNational()}
              </Text>
              <Text component="p" pLineHeight={1.5}>
                and there was no answer.
              </Text>
            </Inline>
            {/* @ts-ignore */}
            <TextLink href="/dash/settings">
              <div>Click here to verify or change your phone number</div>
            </TextLink>
          </Stack>
          <Divider />
          <Box>
            <Inline alignX="between" alignY="center" nowrap space="m" grow={[1, 1]}>
              <SecondaryButton onClick={close} fullWidth>
                Close
              </SecondaryButton>

              <PrimaryButton disabled={false} onClick={callLeadAgain} fullWidth>
                Try Again
              </PrimaryButton>
            </Inline>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default FailedToCallUserModal

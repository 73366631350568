/* eslint no-undef: 0 */
import React, { useState, useEffect } from 'react'

import backend from '../../backend'
import { OK } from '../../constants/error_codes'
import { setUserProperties, resetIdentity } from '../../helpers/heap'
import * as Sentry from '@sentry/react'

export const UserContext = React.createContext()

function AuthProvider(props) {
  const [user, setUserInState] = useState()

  useEffect(() => {
    if (window.fcWidget && user && user.id) {
      const fc = window.fcWidget
      fc.setExternalId(user.id.toString())
      fc.user.setFirstName(user.first_name)
      fc.user.setLastName(user.last_name)
      fc.user.setEmail(user.email)
    }
  }, [window.fcWidget, user])

  const setUser = (user, token) => {
    if (token) {
      localStorage.setItem('token', token)
    }

    setUserInState(user)
    setUserProperties(user)
    try {
      Sentry.setUser({ id: user.id, email: user.email })
    } catch (e) {
      console.log(e)
    }
  }

  const removeUser = () => {
    localStorage.removeItem('token')

    setUserInState(null)

    resetIdentity()

    if (window.fcWidget) {
      window.fcWidget.user.clear()
    }
  }

  const refreshUser = async () => {
    if (localStorage.getItem('token')) {
      try {
        const resp = await backend.get('/users/me')

        if (resp.status === OK) {
          setUser(resp.body)
        }
      } catch (e) {
        console.log(e)
        removeUser()
      }
    } else {
      removeUser()
    }
  }

  const userHasRole = ({ role, teamId }) => {
    if (!user || !user.roles) return false
    return Boolean(
      user.roles.find((userRole) => userRole.role === role && userRole.teamId === teamId)
    )
  }

  useEffect(async () => {
    await refreshUser()
  }, [])

  if (user || user === null) {
    return (
      <UserContext.Provider value={{ user, setUser, removeUser, refreshUser, userHasRole }}>
        {props.children}
      </UserContext.Provider>
    )
  } else {
    return <></>
  }
}

export default AuthProvider

import { find } from 'lodash'
import { isPromoValid } from './promos'

export const formatCurrency = (cents: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)
}

export const formatUSD = (cents: number) => `$${(cents / 100).toFixed(2)}`

export const formatSubscription = (cents: number) => `$${cents / 100}`

export const dollarsFrom = (cents: number) => (cents / 100).toFixed()

export const prettyDollars = (dollars: number) =>
  `$${dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

export const getCostPerLead = (campaign: any, organizationId: number, userId?: number) => {
  let pricing = find(campaign?.product?.pricing, { state: null, organization_id: organizationId })
  if (!pricing) {
    pricing = find(campaign.product?.pricing, { state: null }) || {}
  }
  let adjustedDiscount = 1

  if (campaign.targeted_states && campaign.targeted_states.length > 0) {
    if (campaign.targeted_states[0].state) {
      const statePricing = find(campaign.product.pricing, {
        state: campaign.targeted_states[0].state,
      })
      if (statePricing && statePricing.price) pricing = statePricing
    } else {
      const statePricing = find(campaign.product.pricing, {
        state: campaign.targeted_states[0],
      })
      if (statePricing && statePricing.price) pricing = statePricing
    }
  }

  if (campaign.states && campaign.states.length > 0) {
    const statePricing = find(campaign.product.pricing, {
      state: campaign.states[0].state,
    })
    if (statePricing && statePricing.price) pricing = statePricing
  }

  let price = pricing.price

  const { promotion } = campaign

  if (isPromoValid(promotion) && !promotion?.allocation_group_id) {
    if (promotion.fixed_discount) {
      price -= promotion.fixed_discount
    } else {
      adjustedDiscount -= promotion.discount
    }
  }

  // For pages querying for specific promotion by id
  if (promotion?.allocation_group_id) {
    if (promotion.amount_remaining < price * promotion.discount_percent) {
      //can only discount up to the amount remaining for an allocation
      return (price -= promotion.amount_remaining)
    } else {
      adjustedDiscount -= promotion.discount_percent
      return price * adjustedDiscount
    }
  }

  let { allocation_group, user_allocation } = campaign

  // Filter to specific user's allocation for campaigns list
  if (user_allocation?.length > 1) {
    user_allocation = user_allocation.filter((allocation: any) => allocation.to_user_id === userId)
  }

  if (allocation_group && user_allocation) {
    if (user_allocation[0]?.amount_remaining < price * allocation_group?.discount_percent) {
      price -= user_allocation[0]?.amount_remaining
    } else {
      price *= allocation_group?.discount_percent
    }
  }

  return price * adjustedDiscount
}

import React, { useContext, useEffect, useState } from 'react'

import { Box, Dialog, Text, Stack, Inline, Icon, TextLink, Loader } from '@leadrilla/pulsar'
import { isLeadrilla, useTenantConfig } from '../../hooks/TenantConfig'
import { UserContext } from '../AuthProvider/auth_provider'
import styled from '@emotion/styled'
import useImage from '../../hooks/image'
import SubscriptionPayment from './subscription_payment'
import { useFetcher } from '../../hooks/fetcher'
import { SubscriptionResponse } from '../SubscriptionCard/subscription_card'
import { getSubscriptionDate } from '../../helpers/time'
import { formatSubscription } from '../../helpers/currency'
import SubscriptionCancel from './subscription_cancel'
import { DateTime } from 'luxon'
import SubscriptionInactive from './subscription_inactive'
import SuccessMessage from './welcome_message'

export enum SubscriptionDialogType {
  SUBSCRIBE = 'subscribe',
  MANAGE = 'manage',
  INACTIVE = 'inactive',
}

export enum SuccessType {
  SUBSCRIBED = 'subscribed',
  UPDATED = 'updated',
  CANCELED = 'canceled',
}

type DialogStateClosed = {
  successType?: undefined
  showSuccessMessage: false
  showPaymentForm: true
  showCancel: false
}

type DialogStateCanceled = {
  successType?: undefined
  showSuccessMessage?: undefined
  showPaymentForm: false
  showCancel: true
}

type DialogStateManage = {
  successType?: undefined
  showSuccessMessage: false
  showPaymentForm: true
  showCancel: false
}

type DialogStateSuccessfulCancel = {
  successType: SuccessType.CANCELED
  showSuccessMessage: true
  showPaymentForm: false
  showCancel: false
}

type DialogStateSuccessfulPaymentUpdate = {
  successType: SuccessType.UPDATED
  showSuccessMessage: true
  showPaymentForm: false
  showCancel?: undefined
}

type DialogStateSuccessfulSubscription = {
  successType: SuccessType.SUBSCRIBED
  showSuccessMessage: true
  showPaymentForm: false
  showCancel?: undefined
}

type DialogState =
  | DialogStateClosed
  | DialogStateCanceled
  | DialogStateManage
  | DialogStateSuccessfulCancel
  | DialogStateSuccessfulPaymentUpdate
  | DialogStateSuccessfulSubscription

interface SubscriptionDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  type?: SubscriptionDialogType
}

function SubscriptionDialog({
  open,
  type = SubscriptionDialogType.SUBSCRIBE,
  setOpen,
}: SubscriptionDialogProps) {
  const { user } = useContext(UserContext)
  const [subscription, setSubscription] = useState<SubscriptionResponse>()
  const [dialogType, setDialogType] = useState<SubscriptionDialogType>(type)
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan>()

  const [{ showCancel, showPaymentForm, showSuccessMessage, successType }, setDialogState] =
    useState<DialogState>({
      successType: undefined,
      showSuccessMessage: false,
      showPaymentForm: true,
      showCancel: false,
    })

  const tenantConfig = useTenantConfig()

  const doesNotHaveSubscription = !user.subscription?.activated_at || user.subscription?.canceled_at

  const subscriptionExpired = (date: string) => {
    // check if subscription is expired by comparing to current date with the subscription end date
    const currentDate = DateTime.local()
    const expiresAt = DateTime.fromISO(date)

    return currentDate > expiresAt
  }

  const subscriptionIsActive =
    user.admin ||
    (user.subscription?.activated_at && !subscriptionExpired(user.subscription?.expires_at))

  useEffect(() => {
    if (user.subscription && !subscriptionIsActive) setDialogType(SubscriptionDialogType.INACTIVE)
  }, [user])

  const { image: iconLogo } = useImage(
    isLeadrilla() ? 'leadrilla-logo-blue.svg' : `${tenantConfig.tenant}-logo.svg`
  )

  const canClose = !!subscriptionIsActive && !showCancel

  const { loading: loadingSubscription } = useFetcher('/subscriptions', {
    afterFetch: (data: SubscriptionResponse) => {
      if (data?.id) {
        setSubscription(data)
      }
    },
  })

  interface SubscriptionPlanReponse {
    plan: SubscriptionPlan
  }

  interface SubscriptionPlan {
    enabled: boolean
    price?: number
  }

  const { loading: subscriptionPlanLoader } = useFetcher('/subscriptions/plan', {
    afterFetch: (data: SubscriptionPlanReponse) => {
      setSubscriptionPlan(data?.plan)
    },
    condition: !user.subscription,
  })

  const handleDialogStateChange = (newState: DialogState) => {
    setDialogState(newState)
    if (
      newState.successType === undefined &&
      newState.showSuccessMessage === false &&
      newState.showPaymentForm === true &&
      newState.showCancel === false
    ) {
      setOpen(false)
    }
  }

  return (
    <>
      {open && (
        <StyledDialog
          open={open}
          onClose={() =>
            handleDialogStateChange({
              showSuccessMessage: false,
              showPaymentForm: true,
              showCancel: false,
            })
          }
          radius="m"
          closeOnClick={canClose}
        >
          {canClose && (
            <Box
              position="absolute"
              top={[24, 32]}
              right={[24, 32]}
              cursor="pointer"
              onClick={() => setOpen(false)}
            >
              <Icon name="close" tone="light" size="m" />
            </Box>
          )}
          {!showCancel && (
            <StyledDialogBox
              maxWidth={['100%', '100%', '1200px']}
              inset={['l', 'xl', 'xxl']}
              display={['block', 'flex']}
              flexDirection={'row'}
              flexGrow={['2']}
              radius="s"
            >
              {showPaymentForm && (
                <>
                  <Box width={['100%', '50%']} paddingRight={[null, 'xl', 'xxl']}>
                    <FullHeightStack>
                      <Box flexGrow={1} maxWidth={['50%', '200px']}>
                        <StyledLogo src={iconLogo || ''} alt={'logo'} />
                      </Box>
                      {dialogType === 'manage' && (
                        <>
                          <Box flexGrow={1}>
                            <Box
                              display="flex"
                              justifyContent={['center', 'start']}
                              alignItems={['center', 'start']}
                              marginTop={['l', 'xxl']}
                              marginBottom={['l', 'xl']}
                            >
                              <HeaderText weight="fat">
                                {user.subscription.canceled_at
                                  ? 'Resubscribe'
                                  : 'Manage Subscription'}
                              </HeaderText>
                            </Box>
                            <Box marginBottom={['m', 'xxl']}>
                              <Loader
                                height={148}
                                loading={loadingSubscription || subscriptionPlanLoader}
                              >
                                <Stack space="m">
                                  <Box>
                                    <Text component="p" weight="stronger" size={['base', 'bigger']}>
                                      Subscription cost:
                                    </Text>
                                    <Text component="p" size={['base', 'bigger']}>
                                      {subscription?.subscription_fee &&
                                        formatSubscription(subscription?.subscription_fee)}
                                      /mo
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Text component="p" weight="stronger" size={['base', 'bigger']}>
                                      Last billing date:
                                    </Text>
                                    <Text component="p" size={['base', 'bigger']}>
                                      {subscription?.current_period_start
                                        ? getSubscriptionDate(subscription?.current_period_start)
                                        : ''}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Text component="p" weight="stronger" size={['base', 'bigger']}>
                                      {!user.subscription.canceled_at
                                        ? 'Next billing date:'
                                        : 'Expires on:'}
                                    </Text>
                                    <Text component="p" size={['base', 'bigger']}>
                                      {subscription?.current_period_end
                                        ? getSubscriptionDate(subscription?.current_period_end)
                                        : ''}
                                    </Text>
                                  </Box>
                                </Stack>
                              </Loader>
                            </Box>
                          </Box>

                          {!user.subscription?.canceled_at && (
                            <TextLink
                              href="#"
                              onClick={() =>
                                handleDialogStateChange({
                                  showPaymentForm: false,
                                  showCancel: true,
                                })
                              }
                            >
                              Cancel Subscription
                            </TextLink>
                          )}
                        </>
                      )}
                      {doesNotHaveSubscription && dialogType === 'subscribe' && (
                        <>
                          <Box paddingTop={['m', 'xl']}>
                            <Inline space="xs" alignY="bottom">
                              <SubscriptionPrice>
                                {subscriptionPlan?.price
                                  ? formatSubscription(subscriptionPlan.price)
                                  : ''}
                              </SubscriptionPrice>
                              <Box marginBottom={['m', 'l']}>
                                <Text tone="light" weight="stronger" size={['big', 'bigger']}>
                                  / month
                                </Text>
                              </Box>
                            </Inline>
                          </Box>
                          <Box marginY="m">
                            <Text weight="stronger" size={['big', 'bigger']}>
                              Subscribe today to gain access to the platform.
                            </Text>
                          </Box>
                          <Stack space="m">
                            <Inline space="m" alignY="center" nowrap>
                              <Icon name="success-hollow" size={['l', 'xl']} tone="action" />
                              <Text size="big" component="p">
                                Connect with in-market consumers in real-time
                              </Text>
                            </Inline>
                            <Inline space="m" alignY="center" nowrap>
                              <Icon name="success-hollow" size={['l', 'xl']} tone="action" />
                              <Text size="big" component="p">
                                Stay organized & focus on selling with a built-in CRM
                              </Text>
                            </Inline>
                            <Inline space="m" alignY="center" nowrap>
                              <Icon name="success-hollow" size={['l', 'xl']} tone="action" />
                              <Text size="big" component="p">
                                Support team standing by to assist you
                              </Text>
                            </Inline>
                          </Stack>
                        </>
                      )}
                      {dialogType === 'inactive' && <SubscriptionInactive />}
                    </FullHeightStack>
                  </Box>

                  <SubscriptionPayment
                    dialogType={dialogType}
                    updateSuccess={() =>
                      handleDialogStateChange({
                        successType: SuccessType.UPDATED,
                        showSuccessMessage: true,
                        showPaymentForm: false,
                      })
                    }
                    subscribeSuccess={() =>
                      handleDialogStateChange({
                        successType: SuccessType.SUBSCRIBED,
                        showSuccessMessage: true,
                        showPaymentForm: false,
                      })
                    }
                    subscriptionFee={subscription?.subscription_fee || subscriptionPlan?.price}
                    currentPaymentMethod={subscription?.payment_method.id.toString()}
                  />
                </>
              )}

              {showSuccessMessage && successType && (
                <SuccessMessage
                  type={successType}
                  close={() =>
                    handleDialogStateChange({
                      showSuccessMessage: false,
                      showPaymentForm: true,
                      showCancel: false,
                    })
                  }
                />
              )}
            </StyledDialogBox>
          )}

          {showCancel && subscription && (
            <SubscriptionCancel
              goBack={() =>
                handleDialogStateChange({
                  showSuccessMessage: false,
                  showPaymentForm: true,
                  showCancel: false,
                })
              }
              subscription={subscription}
              success={() =>
                handleDialogStateChange({
                  successType: SuccessType.CANCELED,
                  showSuccessMessage: true,
                  showPaymentForm: false,
                  showCancel: false,
                })
              }
            />
          )}
        </StyledDialog>
      )}
    </>
  )
}

const HeaderText = styled(Text)`
  white-space: nowrap;
  font-size: 32px;
  @media (min-width: 960px) {
    font-size: 42px;
  }
`

const SubscriptionPrice = styled('p')`
  font-size: 64px;
  font-weight: 900;

  @media (min-width: 720px) {
    font-size: 96px;
  }
`

const StyledDialog = styled(Dialog)`
  div > div > div > div > div > div {
    border-radius: 16px !important;
  }
  border-radius: 16px !important;
`

const StyledDialogBox = styled(Box)`
  border-radius: 16px;
`

const StyledLogo = styled('img')`
  max-height: 65px;
`

const FullHeightStack = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export default SubscriptionDialog

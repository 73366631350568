import { Card, Inline, Stack, Text, Loader } from '@leadrilla/pulsar'
import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { useFetcher } from '../../hooks/fetcher'
import { CallContext } from './CallContext'
import { DateTime } from 'luxon'
import { formatDuration } from '../../helpers/time'
import { formatUSD } from '../../helpers/currency'
import { useTenantConfig } from '../../hooks/TenantConfig'

const Timeline = () => {
  const callContext = useContext(CallContext)
  const tenantConfig = useTenantConfig()
  const [timelineItems, setTimelineItems] = useState([])

  const { loading } = useFetcher(`/leads/${callContext.lead?.id}/`, {
    afterFetch: (lead: any) => {
      makeTimelineItems(lead)
    },
    dependencies: [callContext.lead],
  })

  const makeTimelineItems = (lead: any) => {
    if (!lead?.statuses?.length) return

    const items = lead.statuses.concat(lead.comments)

    if (lead.call) {
      items.push({ created_at: lead.call.created_at, call: lead.call })
    }
    if (lead.lead_credit_issuance_reason) {
      items.push({
        created_at: lead.lead_credit_issuance_date,
        amount: lead.lead_credit_issuance_amount,
        lead_credit_issuance: true,
      })
    }

    items.sort((a: any, b: any) => {
      //@ts-ignore
      return DateTime.fromISO(b.created_at).ts - DateTime.fromISO(a.created_at).ts
    })

    if (lead.transaction && lead.transaction.type === 'marketplace_order') {
      setTimelineItems(
        items.concat([
          { transaction: lead.transaction, created_at: items[items.length - 1].created_at },
          {
            content: (
              //@ts-ignore
              <p bp="margin--none">
                {lead.name} responded to an ad and became a lead on {tenantConfig.name}.
              </p>
            ),
            created_at: lead.created_at,
          },
        ])
      )
    } else if (
      lead.product.type === 'leads' &&
      (lead.product.sms_communication || lead.product.email_communication)
    ) {
      //addLeadCommunicationToTimeline(items, lead)
    } else {
      // setTimelineItems(items)
    }
    setTimelineItems(items)
  }

  const renderStatus = (item: any) => {
    return (
      <Text>
        Status updated to <span className="bold caps">{item.status}</span>.
      </Text>
    )
  }
  const renderComment = (item: any) => {
    return (
      <>
        <Text>Note added</Text>
        {/* @ts-ignore */}
        <div bp="padding--sm" className="lr-blockquote">
          {item.comment}
        </div>
      </>
    )
  }
  const renderSms = (item: any) => {
    return (
      <>
        <Text component="p">Text message sent to {callContext?.lead?.phone}.</Text>
        {/*@ts-ignore */}
        <div bp="padding--sm" className="lr-blockquote">
          {item.sms}
        </div>
      </>
    )
  }
  const renderCall = (item: any) => {
    return (
      <Stack space="m">
        <Text>
          {callContext.lead?.product?.name} ({formatDuration(parseInt(item.call.duration, 10))})
        </Text>

        {item.call.recording_url && (
          <audio controls style={{ outline: 'none' }}>
            <source src={item.call.recording_url} type="audio/mpeg" />
            Your browser does not support audio
          </audio>
        )}
      </Stack>
    )
  }

  const renderTransaction = () => {
    return <Text>Purchased from the Marketplace.</Text>
  }
  const renderLeadCreditIssue = (item: any) => {
    return <Text>Refund issued: {formatUSD(item.amount)}</Text>
  }
  // todo - update styling to use pulsar instead of blueprint
  return (
    <Loader loading={loading} height={300}>
      {timelineItems /*
          example:
            <StyledStack space="m">
              <Text weight="stronger">Timeline</Text>
              <Card insetStretch="xl" insetSquish="l" >
                <Stack space="xs">
                  <Inline alignX="between" alignY="top">
                    <Text weight="stronger">Oct 26th, 2022</Text>
                    <Text weight="stronger">9:11am</Text>
                  </Inline>
                  Status updated to NO ANSWER - 3
                </Stack>
              </Card>
            </StyledStack>
            */ && (
        <StyledStack space="m">
          {timelineItems.map((item: any) => {
            const date = DateTime.fromISO(item.created_at).toFormat('MMM d, yyyy')
            const time = DateTime.fromISO(item.created_at).toFormat('h:mma').toLowerCase()

            return (
              <React.Fragment key={item.id}>
                <Card insetStretch="m">
                  <Stack space="s">
                    <Inline alignX="between" alignY="top">
                      <Text weight="stronger">{date}</Text>
                      <Text weight="stronger">{time}</Text>
                    </Inline>

                    {item.content}
                    {item.comment && renderComment(item)}
                    {item.status && renderStatus(item)}
                    {item.sms && renderSms(item)}
                    {item.transaction && renderTransaction()}
                    {item.call && renderCall(item)}
                    {item.lead_credit_issuance && renderLeadCreditIssue(item)}
                  </Stack>
                </Card>
              </React.Fragment>
            )
          })}
        </StyledStack>
      )}
    </Loader>
  )
}
const StyledStack = styled(Stack)`
  height: 100%;
`
export default Timeline

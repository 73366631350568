import semver from 'semver'

import { UNAUTHORIZED } from './constants/error_codes'

const backend = async (
  method: string,
  uri: string,
  body?: { [key: string]: any },
  options?: { headers: HeadersInit }
) => {
  const headers = new Headers({
    Accept: 'application/json',
    'X-Current-Team-Id': sessionStorage.getItem('current_team_id') ?? '',
    ...options?.headers,
  })

  const token = localStorage.getItem('token')
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  if (method !== 'get') {
    headers.set('Content-Type', 'application/json')
  }

  let response

  try {
    response = await fetch(`${process.env.REACT_APP_API_URL}${uri}`, {
      method,
      headers,
      body: JSON.stringify(body),
    })
  } catch (err) {
    return { ok: false, status: 500 }
  }

  if (uri === '/users/me' && window.location.pathname !== '/' && response.status !== 200 && token) {
    localStorage.removeItem('token')
    window.location.assign('/')
  }

  let responseBody

  try {
    responseBody = await response.json()
  } catch (err) {
    responseBody = { success: false }
  }

  if (
    !uri.includes('login') &&
    response.status === UNAUTHORIZED &&
    responseBody.message === 'Account disabled'
  ) {
    alert('Your account has been disabled. You will now be logged out.')
  }

  try {
    const versionFromApi = response.headers.get('X-Client-Version')
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_VERSION &&
      versionFromApi &&
      semver.lt(process.env.REACT_APP_VERSION, versionFromApi)
    ) {
      window.location.reload()
    }
  } catch (e) {
    // Do nothing
  }

  return {
    ok: response.ok,
    status: response.status,
    body: responseBody,
    error: !response.ok
      ? new BackendError({
          name: responseBody?.error,
          message: responseBody?.message,
          code: response.status,
        })
      : null,
  }
}

const methods = {
  get: backend.bind(null, 'get'),
  post: backend.bind(null, 'post'),
  put: backend.bind(null, 'put'),
  delete: backend.bind(null, 'delete'),
}

export class BackendError extends Error {
  name: string
  code: number

  constructor({ name, message, code }: { name: string; message: string; code: number }) {
    super(message)
    this.name = name
    this.code = code
  }
}

export default methods

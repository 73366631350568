import { DateTime } from 'luxon'
import { isNil } from 'lodash'
import React from 'react'
import { formatUSD } from './currency'

export const buildPromoMessage = (
  promotion,
  productType = 'leads',
  teamPromoCode,
  userAllocation
) => {
  let promoMessage = null

  if (promotion) {
    const uses = promotion.uses_per_user || promotion.uses

    let timesUsed = promotion.times_used || 0
    if (promotion.uses_per_user) {
      timesUsed = promotion.times_used_by_user || 0
    }

    const endsAt = promotion.ends_at ? DateTime.fromISO(promotion.ends_at) : null

    if (isNil(uses) || timesUsed < uses) {
      const number =
        !isNil(uses) && !isNil(timesUsed) ? (
          <>
            Your next <strong>{uses - timesUsed}</strong> {productType} are{' '}
          </>
        ) : (
          <>All {productType} are </>
        )

      let discount
      if (promotion.fixed_discount) {
        discount = (
          <>
            <strong>{formatUSD(promotion.fixed_discount)}</strong> off
          </>
        )
      } else {
        discount = (
          <>
            <strong>{promotion.discount * 100}%</strong> off
          </>
        )
      }
      const until = promotion.ends_at ? (
        <>
          &nbsp;until&nbsp;
          <strong>
            {endsAt.toLocaleString({
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'America/New_York',
              timeZoneName: 'short',
            })}
          </strong>
        </>
      ) : (
        ''
      )

      promoMessage = (
        <>
          {promotion.code}: {number}
          {discount}
          {until}
        </>
      )
    }
  }

  if (promotion?.allocation_group_id || promotion?.type === 'promo-code') {
    promoMessage = (
      <>
        {teamPromoCode.toUpperCase()}: Up to {promotion.discount_percent * 100}% off, up to{' '}
        {formatUSD(promotion.amount_per_recipient)}
      </>
    )
  }

  return promoMessage
}

export const isPromoValid = (promotion) => {
  if (isNil(promotion)) return false

  if (promotion.archived) return false

  if (promotion.ends_at) {
    const endsAt = DateTime.fromISO(promotion.ends_at)

    if (endsAt && endsAt.diffNow('seconds').seconds < 0) return false
  }

  const uses = promotion.uses_per_user || promotion.uses

  let timesUsed = promotion.times_used || 0
  if (promotion.uses_per_user) {
    timesUsed = promotion.times_used_by_user || 0
  }

  return isNil(uses) || timesUsed < uses
}

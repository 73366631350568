import React from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Stack,
  Inline,
  WrapperButton,
  PrimaryButton,
  SecondaryButton,
  Text,
  Icon,
} from '@leadrilla/pulsar'

import logo from '../../assets/svg/leadrilla-logo-horizontal-black.svg'

function NavItem({ to, icon, text, close, ...props }) {
  return (
    <StyledNavItem
      onClick={() => {
        props.history.push(to)
        close()
      }}
    >
      <Inline space="l" alignY="center">
        <Icon name={icon} size="l" />
        <Text size="bigger">{text}</Text>
      </Inline>
    </StyledNavItem>
  )
}

function WebsiteMobileMenu({ close, ...props }) {
  const goTo = (to) => {
    props.history.push(to)
    close()
  }

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      background="white"
      width="100%"
      inset="l"
      {...props}
    >
      <Inline alignX="between" alignY="center">
        <Box width="116">
          <StyledMobileLogo src={logo} alt="brand logo" />
        </Box>
        <WrapperButton onClick={close}>
          <Icon name="close" tone="subtle" size="l" />
        </WrapperButton>
      </Inline>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="between"
        height="100%"
        width="100%"
        marginTop="xl"
      >
        <Stack space="xl" align="start">
          <NavItem to="/" text="Home" icon="home-tt" close={close} {...props} />
          <NavItem to="/leads" text="Leads" icon="users-tt" close={close} {...props} />
          <NavItem
            to="/leads/call-campaigns"
            text="Calls"
            icon="phone-tt"
            close={close}
            {...props}
          />
          <NavItem to="/contact" text="Contact" icon="contact-tt" close={close} {...props} />
        </Stack>
        <Box display="flex" justifyContent="between" paddingTop="l">
          <Box width="45%">
            <SecondaryButton
              fullWidth
              size="bigger"
              onClick={() => {
                goTo('/login')
              }}
            >
              Log in
            </SecondaryButton>
          </Box>
          <Box width="45%">
            <PrimaryButton
              fullWidth
              size="bigger"
              onClick={() => {
                goTo('/signup')
              }}
            >
              Sign up
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const StyledNavItem = styled('div')`
  cursor: pointer;
  width: 100%;
`
const StyledMobileLogo = styled('img')`
  max-height: 34px;
`
export default styled(WebsiteMobileMenu)`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: var(--space--l);
`

import React, { useContext, useState } from 'react'
import { Text, Box, TextArea, Dropdown, Stack, PrimaryButton } from '@leadrilla/pulsar'
import styled from '@emotion/styled'
import { analyticsEvent } from '../../helpers/heap'
import backend from '../../backend'
import { OK } from '../../constants/error_codes'
import { actionType, CallContext, useCallCampaignDispatch } from './CallContext'
import { useNotification } from '../../hooks/notification'
import { useFetcher } from '../../hooks/fetcher'
const Notes: React.FC = () => {
  const [notes, setNotes] = useState<string>('')
  const [outcome, setOutcome] = useState(null)
  const [outcomeOptions, setOutcomeOptions] = useState<any>([{}])
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const sendNotification = useNotification()
  const callContext = useContext(CallContext)
  const dispatch = useCallCampaignDispatch()

  useFetcher('/leads/all/statuses', {
    afterFetch: (options: any) => {
      try {
        const statuses = Object.entries(options)?.map((option: any) => ({
          text: option[1].name,
          value: option[0],
        }))
        setOutcomeOptions(statuses)
      } catch (e) {
        console.log(e)
      }
    },
  })
  interface Status {
    readonly id: number
    product_id: number
    status: string
    name: string
    tone: 'light' | 'action' | 'positive' | 'negative' | 'caution' | 'info'
  }
  interface StatusResponse {
    status?: Status
  }
  useFetcher(`/leads/${callContext?.lead?.id}/status`, {
    afterFetch: (data: StatusResponse) => {
      const status = data.status
      if (status) processOutcome(status.status)
    },
    dependencies: [callContext.lead],
  })

  const processOutcome = (outcome: any) => {
    setOutcome(outcome)
    analyticsEvent('Presence - Outcome changed', { outcome: outcome.text })
  }
  const updateNotes = (notes: string) => {
    if (notes) {
      sessionStorage.setItem('active-call-notes', notes)
    } else {
      sessionStorage.removeItem('active-call-notes')
    }
    setNotes(notes)
  }

  const submit = async () => {
    if (submitting) return

    setSubmitting(true)
    const { status } = await backend.put(`/leads/${callContext.lead?.id}`, {
      notes,
      status: outcome,
    })
    if (status === OK) {
      if (dispatch)
        dispatch({
          type: actionType.SUBMISSION,
          payload: { enabled: true },
        })
    } else {
      sendNotification({
        type: 'error',
        message: 'Something went wrong. Please refresh and try again.',
      })
    }

    setSubmitted(true)
  }

  let disableSave = !outcome

  return (
    <Box>
      {!submitted ? (
        <Stack space="m">
          <StyledTextArea
            name="notes"
            label="Notes"
            value={notes}
            onChange={(e) => updateNotes(e.target.value)}
            rows={10}
          />
          <Dropdown
            label="Status*"
            options={outcomeOptions}
            value={outcome}
            onChange={(v) => processOutcome(v)}
            optionsMaxHeight="120px"
            native
          />
          <PrimaryButton
            fullWidth
            onClick={submit}
            disabled={disableSave}
            id="presence-save-call-notes-button"
          >
            Save call notes
          </PrimaryButton>
        </Stack>
      ) : (
        <>
          <Text>Outcome submitted</Text>
        </>
      )}
    </Box>
  )
}
const StyledTextArea = styled(TextArea)`
  height: 160px;
  resize: none;
`
export default Notes

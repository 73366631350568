import { useState, useEffect, useRef } from 'react'
import { useDebounce } from 'use-debounce'
import { map, mapValues, groupBy, identity, isNil } from 'lodash'

import backend from '../backend'
import { OK } from '../constants/error_codes'

const skipTakeToString = ({ skip, take }) => `skip${skip}take${take}`

const filtersToString = (filters) =>
  filters
    .map((v) => (v && v.selected ? `${v.key}${JSON.stringify(v.selected.value)}` : null))
    .join('')

const sortedToString = (sorted) => sorted.map((s) => `${s.id}${s.desc ? 'desc' : 'asc'}`).join('')

const buildQueryString = (filters) => {
  return map(
    mapValues(groupBy(filters, 'key'), (f) => map(f, 'selected.value')),
    (value, key) => {
      if (value.length === 1) {
        if (value[0] && typeof value[0] === 'object') {
          return `${key}=${JSON.stringify(value[0])}`
        }
        return `${key}=${value[0]}`
      } else {
        return `${key}=${JSON.stringify(value)}`
      }
    }
  ).join('&')
}

export const useFetcher = (url, passedOptions) => {
  let options = {
    dependencies: [],
    condition: true,
    startLoading: true,
    skipFirstRun: false,
    filter: () => true,
    filters: [],
    textFilter: '',
    sorted: [],
    pagination: false,
    formatter: identity,
    afterFetch: () => {},
    onFailure: () => {},
    defaultData: [],
    defaultSkipTake: { skip: 0, take: 20 },
    ...passedOptions,
  }

  const [data, setData] = useState(options.defaultData)
  const [count, setCount] = useState(0)

  let defaultLoading = options.startLoading
  if (passedOptions && isNil(passedOptions.startLoading) && !isNil(passedOptions.condition)) {
    defaultLoading = options.condition
  }
  const [loading, setLoading] = useState(defaultLoading)

  const [skipTake, setSkipTake] = useState(options.defaultSkipTake)
  const [filterString, setFilterString] = useState('')

  const [textFilter] = useDebounce(options.textFilter, 300)

  const isFirstRunRef = useRef(true)

  useEffect(() => {
    if (options.skipFirstRun && isFirstRunRef.current) {
      isFirstRunRef.current = false
      return
    }

    let isFresh = true

    if (options.condition) {
      setLoading(true)

      let queries = [...options.filters]

      if (options.sorted.length) {
        queries.push({ key: 'order_by', selected: { value: JSON.stringify(options.sorted) } })
      }

      if (options.pagination) {
        queries.push({ key: 'skip', selected: { value: skipTake.skip } })
        queries.push({ key: 'take', selected: { value: skipTake.take } })
      }

      if (textFilter) {
        queries.push({ key: 'text_filter', selected: { value: textFilter } })
      }

      setFilterString(buildQueryString(queries))

      const queryString = buildQueryString(queries)

      let fullUrl = url

      if (queryString) {
        const separator = url.includes('?') ? '&' : '?'
        fullUrl = `${url}${separator}${queryString}`
      }

      backend.get(fullUrl).then(({ status, body }) => {
        if (isFresh) {
          if (status === OK) {
            let newData, newCount

            if (body.hasOwnProperty('data')) {
              newData = body.data.filter(options.filter).map(options.formatter)
              newCount = body.count
            } else if (Array.isArray(body)) {
              newData = body.filter(options.filter).map(options.formatter)
              newCount = newData.length
            } else {
              newData = body
              newCount = 1
            }

            setData(newData)
            setCount(newCount)

            options.afterFetch(body)
          } else {
            options.onFailure({ status, body })
          }

          setLoading(false)
        }
      })
    }

    return () => (isFresh = false)
  }, options.dependencies.concat([options.condition, skipTakeToString(skipTake), filtersToString(options.filters), sortedToString(options.sorted), textFilter]))

  return { data, count, loading, filterString, setData, skipTake, setSkipTake }
}

import React, { useReducer, useState } from 'react'
import { Toast } from '@leadrilla/pulsar'
import { getNonUserSpecificTenantConfig } from '../../hooks/TenantConfig'

export const NotificationContext = React.createContext()

const typesMap = {
  success: 'success',
  error: 'critical',
  caution: 'caution',
  info: 'info',
}

const tenantConfig = getNonUserSpecificTenantConfig()

const reducer = (state, action) => {
  switch (action.type) {
    case 'hide':
      return {
        show: false,
        type: state.type,
      }
    case 'error':
      return {
        show: true,
        type: typesMap[action.type],
        message: action.message || (
          <>
            Oops, something went wrong! If the problem persists, please email us at{' '}
            <a href={tenantConfig.support_email}>{tenantConfig.support_email}</a>.
          </>
        ),
      }
    default:
      return {
        show: true,
        type: typesMap[action.type],
        message: action.message,
      }
  }
}

function NotificationBar(props) {
  const [state, dispatch] = useReducer(reducer, { show: false })
  const [timeoutId, setTimeoutId] = useState()

  const sendNotification = (action) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    if (action.type !== 'hide') {
      setTimeoutId(setTimeout(() => dispatch({ type: 'hide' }), 10000))
    }

    dispatch(action)
  }

  return (
    <>
      <Toast
        show={state.show}
        type={state.type}
        message={state.message}
        onClose={() => dispatch({ type: 'hide' })}
      />

      <NotificationContext.Provider value={sendNotification}>
        {props.children}
      </NotificationContext.Provider>
    </>
  )
}

export default NotificationBar

// TODO - convert to named parameters instead of positial parameters
// TODO - improve types to ensure that the User actually has an array of Teams
// TODO - write tests
export const isUserAdminForTeam = (user: any, teamId: string) => {
  if (!user || !user.teams) return false

  // Platform admins have Team admin permissions for all teams
  if (user.type === 'admin') return true

  const team = user.teams.find((team: any) => team.id === teamId)
  return team && team.is_admin
}

export const isAdminForAtLeastOneTeam = (teams: any) => {
  return teams.some((team: any) => team.is_admin)
}

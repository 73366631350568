import { Text, Icon } from '@leadrilla/pulsar'
import styled from '@emotion/styled'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { isLeadrilla } from '../../hooks/TenantConfig'

export const AdminOptions = () => {
  const { pathname } = useLocation()

  return (
    <div className="space-y-12 pt-12">
      <div className="pl-4">
        <Text size="big" weight="strong" tone="light">
          Admin
        </Text>
      </div>
      <div>
        <StyledNavLink
          className={cn({ selected: pathname === '/dash/metrics' })}
          to="/dash/metrics"
        >
          <div className="flex items-center space-x-3 pl-4">
            {pathname === '/dash/metrics' ? (
              <StyledIcon name="metrics-tt"></StyledIcon>
            ) : (
              <StyledIcon name="metrics"></StyledIcon>
            )}
            <StyledText>Metrics</StyledText>
          </div>
        </StyledNavLink>
      </div>
      <div>
        <StyledNavLink className={cn({ selected: pathname === '/dash/users' })} to="/dash/users">
          <div className="flex items-center space-x-3 pl-4">
            {pathname === '/dash/users' ? (
              <StyledIcon name="users-tt"></StyledIcon>
            ) : (
              <StyledIcon name="users"></StyledIcon>
            )}
            <StyledText>Users</StyledText>
          </div>
        </StyledNavLink>
      </div>
      <div>
        <StyledNavLink
          className={cn({ selected: pathname === '/dash/promotions' })}
          to="/dash/promotions"
        >
          <div className="flex items-center space-x-3 pl-4">
            {pathname === '/dash/promotions' ? (
              <StyledIcon name="celebrate-tt"></StyledIcon>
            ) : (
              <StyledIcon name="celebrate"></StyledIcon>
            )}
            <StyledText>Promotions</StyledText>
          </div>
        </StyledNavLink>
      </div>
      {isLeadrilla() && (
        <div className="space-y-12">
          <StyledNavLink
            className={cn({ selected: pathname === '/dash/call-demand' })}
            to="/dash/call-demand"
          >
            <div className="flex items-center space-x-3 pl-4">
              {pathname === '/dash/call-demand' ? (
                <StyledIcon name="phone-tt"></StyledIcon>
              ) : (
                <StyledIcon name="phone"></StyledIcon>
              )}
              <StyledText>Call Demand</StyledText>
            </div>
          </StyledNavLink>
          <StyledDiv className="flex items-center space-x-3 pl-4">
            <StyledIcon name="flow" />
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://leadrilla.retool.com/apps/03e50820-2522-11ec-846a-c772c5dcdc0c"
            >
              Bid Management
            </StyledLink>
          </StyledDiv>
          <StyledDiv className="flex items-center space-x-3 pl-4">
            <StyledIcon name="acquire" />
            <StyledText>
              <StyledLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://leadrilla.retool.com/apps/5cc4828e-94b9-11ec-8c2f-7b0a30f4c87d/"
              >
                Buyer Campaigns
              </StyledLink>
            </StyledText>
          </StyledDiv>
          <StyledDiv className="flex items-center space-x-3 pl-4">
            <StyledIcon name="add_to_user" />
            <StyledText>
              <StyledLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://leadrilla.retool.com/apps/4021d1ca-ecb8-11ec-9240-6fff2e47d3d6/"
              >
                Org Management
              </StyledLink>
            </StyledText>
          </StyledDiv>
        </div>
      )}
    </div>
  )
}

const StyledLink = styled('a')`
  color: inherit;
  &:hover {
    color: var(--button-hover--primary);
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

const StyledText = styled(Text)`
  color: inherit;
  font-weight: inherit;
`

const StyledIcon = styled(Icon)`
  color: inherit !important;
`

const StyledNavLink = styled(NavLink)`
  color: #515459;

  &:hover {
    color: var(--button-hover--primary) !important;
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

const StyledDiv = styled.div`
  color: #515459;

  &:hover {
    color: var(--button-hover--primary);
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

import React, { useContext, useState } from 'react'
import { Box, Stack, Text, Strong } from '@leadrilla/pulsar'
import { SuccessType } from './subscription_dialog'
import { SubscriptionResponse } from '../SubscriptionCard/subscription_card'
import { getSubscriptionDate } from './helpers'
import { useFetcher } from '../../hooks/fetcher'
import { UserContext } from '../AuthProvider/auth_provider'
import { toTitleCase } from '../../helpers/utils'
import SuccessPanel from './success_panel'

interface WelcomeMessageProps {
  type: SuccessType
  close: any
}

const WelcomeMessage = ({ type, close }: WelcomeMessageProps) => {
  const [subscription, setSubscription] = useState<SubscriptionResponse | null>()
  const { user } = useContext(UserContext)

  useFetcher('/subscriptions/', {
    afterFetch: (data: SubscriptionResponse) => {
      if (data?.id) {
        setSubscription(data)
      }
    },
  })

  return (
    <>
      <Box maxWidth={['300px', '600px']}>
        {type === 'subscribed' && (
          <SuccessPanel>
            <SuccessPanel.Heading>Welcome, {user.first_name}!</SuccessPanel.Heading>
            <SuccessPanel.BodyContent>
              <Text component="p" size="big">
                Your subscription is now active. You can manage your subscription and view billing
                history on your Billing page.
              </Text>
            </SuccessPanel.BodyContent>
            <SuccessPanel.ButtonContent close={close}>
              Continue to the Platform
            </SuccessPanel.ButtonContent>
          </SuccessPanel>
        )}

        {type === 'updated' && (
          <SuccessPanel>
            <SuccessPanel.Heading>Payment method updated!</SuccessPanel.Heading>
            <SuccessPanel.BodyContent>
              {/* Static height to prevent modal height changing once subscription is loaded */}
              <Box height={17}>
                {subscription && (
                  <Text component="p" size="big">
                    <Strong>
                      {toTitleCase(subscription.payment_method.brand)} ending in{' '}
                      {subscription.payment_method.last4}
                    </Strong>{' '}
                    will be charged on your next billing date, {getSubscriptionDate(subscription)}.
                  </Text>
                )}
              </Box>
            </SuccessPanel.BodyContent>
            <SuccessPanel.ButtonContent close={close}>Close</SuccessPanel.ButtonContent>
          </SuccessPanel>
        )}

        {type === 'canceled' && (
          <SuccessPanel>
            <SuccessPanel.Heading>Your subscription was canceled.</SuccessPanel.Heading>
            <SuccessPanel.BodyContent>
              {/* Static height to prevent modal height changing once subscription is loaded */}
              <Box height={54}>
                {subscription && (
                  <Stack space="m">
                    <Text weight="stronger">
                      You will have full access to the platform until{' '}
                      {getSubscriptionDate(subscription)}.
                    </Text>
                    <Text>
                      On {getSubscriptionDate(subscription)}, your active campaigns will be
                      automatically paused. You can reactivate your subscription at any time.
                    </Text>
                  </Stack>
                )}
              </Box>
            </SuccessPanel.BodyContent>
            <SuccessPanel.ButtonContent close={close}>Close</SuccessPanel.ButtonContent>
          </SuccessPanel>
        )}
      </Box>
    </>
  )
}
export default WelcomeMessage
